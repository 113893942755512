<template>
  <router-view />
</template>
<script>
export default {
  name: "App",

  data() {
    return {};
  },
};
</script>
<style>
body {
  padding-top: 60px !important;
}
</style>


