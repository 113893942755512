import Vue from "vue"
import VueRouter from "vue-router"
//import store from "./../store/index";



Vue.use(VueRouter)

const router = new VueRouter({
    base: '/',
    //mode: "history",
    routes: [

        //doctor
        {
            path: "/doctor",
            //name: "",
            //redirect: "doctors",
            component: () => import("./../layout/Admin.vue"),
            children: [
                //table
                {
                    path: "table",
                    //name: "",
                    component: () => import("./../views/doctor/Table.vue"),
                    //meta: { requiresAuth: true }
                },
                //register
                {
                    path: ":doctorId?",
                    //name: "",
                    component: () => import("./../views/doctor/Index.vue"),
                    //meta: { requiresAuth: true }
                },
            ]

        },

        //clinic
        {
            path: "/clinic",
            //name: "",
            component: () => import("./../layout/Admin.vue"),
            children: [
                //table
                {
                    path: "table",
                    //name: "",
                    component: () => import("./../views/clinic/Table.vue"),
                    //meta: { requiresAuth: true }
                },
                //register
                {
                    path: ":clinicId?",
                    //name: "",
                    component: () => import("./../views/clinic/Index.vue"),
                    //meta: { requiresAuth: true }
                },
            ]
        },

        //register
        {
            path: "/register",
            //name: "",
            //redirect: "doctors",
            component: () => import("./../layout/Admin.vue"),
            children: [
                //register
                {
                    path: "filter",
                    //name: "",
                    component: () => import("./../views/register/Filter.vue"),
                    //meta: { requiresAuth: true }
                },
                {
                    path: "covenants",
                    //name: "",
                    component: () => import("./../views/register/Covenants.vue"),
                    //meta: { requiresAuth: true }
                },
                {
                    path: "services",
                    //name: "",
                    component: () => import("./../views/register/Services.vue"),
                    //meta: { requiresAuth: true }
                },
                {
                    path: "urls",
                    //name: "",
                    component: () => import("./../views/register/Urls.vue"),
                    //meta: { requiresAuth: true }
                },


            ]

        },

        //auth
        {
            path: "/",
            name: "auth",
            //redirect: "/",
            component: () => import("./../layout/Auth.vue"),
            children: [
                {
                    path: "/",
                    name: "login",
                    component: () => import("./../views/auth/Login.vue"),
                },
                {
                    path: "recover_password",
                    //name: "auth.recover_password",
                    component: () => import("./../views/auth/RecoverPassword.vue"),
                },
                {
                    path: "reset_password/:token",
                    //name: "auth.reset_password",
                    component: () => import("./../views/auth/ResetPassword.vue"),
                },
            ]
        }



    ]
})

/*
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.

        if (!store.getters.token) {
            next({ name: "auth/login" })
        } else {
            next() // go to wherever I'm going
        }
    } else {
        next() // does not require auth, make sure to always call next()!
    }
})*/



export default router