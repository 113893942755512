import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from 'vuex-persist';

Vue.use(Vuex);

const _doctor = {
    treatment: "",
    name: "",
    crm: "",
    rqe: "",
    services: [],
    filters: [],
    offices: [],
    urls: [],
    active: false
}

// root state object.
// each Vuex instance is just a single state tree.
const state = {
    user: { address: {} }, //JSON.parse(JSON.stringify(user)),
    doctor: _doctor,
    loading: false,
    token: "",
    error: false,
}

// mutations are operations that actually mutate the state.
// each mutation handler gets the entire state tree as the
// first argument, followed by additional payload arguments.
// mutations must be synchronous and can be recorded by plugins
// for debugging purposes.
const mutations = {

    //user
    setUser(state, user = {}) {
        for (let key in user) {
            if (key != "token")
                state.user[key] = user[key];
        }
        if (user.token) state.token = user.token;
    },

    setDoctor(state, doctor = _doctor) {
        state.doctor = doctor;
    },

    setLoading(state, value = true) {
        state.loading = value;
    },

    setToken(state, token = "") {
        state.token = token;
        if (!token) state.user = {};
    },

    setError(state, error = false) {
        state.error = error;
    },

}

const getters = {
    user: state => state.user,
    doctor: state => state.doctor,
    loading: state => state.loading,
    token: state => state.token,
    error: state => state.error || false,
}

const vuexLocal = new VuexPersist({
    storage: window.localStorage,
    reducer: state => ({
        user: state.user,
        //doctor: state.doctor,
        token: state.token,
    })
})


export default new Vuex.Store({
    state,
    getters,
    mutations,
    //actions,
    plugins: [vuexLocal.plugin]
});